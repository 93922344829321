
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import debounce from 'lodash-es/debounce';
import Calendar from 'dayjs/plugin/calendar';
import axios, { CancelTokenSource } from 'axios';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Consult, Patient, User } from '@/models';
import {
  BasePagination,
  BaseTextInput,
  DataTable,
  PopoverLink,
  BasePopover,
  BaseIcon,
  SpecialityButton,
  BaseButton,
  PopoverButton,
  LockConsultModal,
  BaseModal
} from '@/lib/components';
import {
  CodeableConceptService,
  EncounterService,
  EpisodeOfCareService
} from '@/services/api';
import PatientSupportingDocumentModal from '@/views/patient/PatientSupportingDocumentModal.vue';
import {
  getDobFromISOString,
  getNhsNumberFromPatient
} from '@/helpers/patient.helper';
import {
  sexOptions,
  genderOptions,
  codeableConceptCategories
} from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import OphthalmologyConsultAggregateService, {
  RequestParams
} from '@/services/aggregate/ophthalmology-consult.aggregate.service';
import OphthalmologyConsultViewModel from '@/models/ophthalmology-consult.view.model';
import { findCodeableConceptByCode } from '@/helpers/codeableConcepts.helper';
import { CodeableConcept } from '@/lib';

dayjs.extend(localizedFormat);

@Options({
  components: {
    LockConsultModal,
    PopoverButton,
    BaseIcon,
    BaseButton,
    PopoverLink,
    SpecialityButton,
    BasePopover,
    BasePagination,
    BaseTextInput,
    DataTable,
    BaseModal,
    PatientSupportingDocumentModal
  }
})
export default class ReviewListPage extends Vue {
  loading = true;
  unWatchRoute = null;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  rows: Partial<Consult>[] = [];
  perPage = 0;
  total = 0;
  search = '';
  filtered = false;
  encounterService = new EncounterService();
  ophthalmologyConsultService = new OphthalmologyConsultAggregateService(
    new EpisodeOfCareService(),
    new CodeableConceptService(),
    new EncounterService()
  );

  request: CancelTokenSource | null = null;

  isModalOpen = false;
  modalTargetPatientId = '';
  modalTargetReviewId = '';
  modalTargetLockedByUserFullName = '';

  selectedDocumentId: number | null = null;

  sexOptions = sexOptions();
  genderOptions = genderOptions();

  // Statuses
  statuses: CodeableConcept[] = [];
  // encounterClasses
  encounterClasses: CodeableConcept[] = [];

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'updated_at';
  }

  get organisationId() {
    return this.$route.params.organisationId;
  }

  get currentUserId() {
    return this.sessionStore.currentUser.id;
  }

  showContinueButton(row): boolean {
    return (
      row.status === 'Reviewing' &&
      row.review &&
      (!row.review.locked || row.review.locked_by.id === this.currentUserId)
    );
  }

  lockedNotByCurrentUser(row): boolean {
    return (
      row.review &&
      row.review.locked &&
      row.review.locked_by.id !== this.currentUserId
    );
  }

  created() {
    this.progressStore.startProgress();
    dayjs.extend(Calendar);
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
    if (this.unWatchRoute) {
      this.unWatchRoute();
    }
  }

  async mounted() {
    this.search = String(this.$route.query.search || '');
    this.fetchConsults();

    try {
      this.statuses =
        await this.ophthalmologyConsultService.fetchCodeableConceptsByCategory(
          codeableConceptCategories.encounterStatus
        );
      this.encounterClasses =
        await this.ophthalmologyConsultService.fetchCodeableConceptsByCategory(
          codeableConceptCategories.encounterClass
        );
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
    }

    this.unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    });

    // Re-fetch consults when switching organisation
    this.$watch('organisationId', async () => {
      this.search = '';
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      await this.fetchConsults();
    });
  }

  async fetchConsults() {
    this.loading = true;

    this.request = await axios.CancelToken.source();

    const requestConfig: RequestParams = {
      include: 'ophthalmologyEpisodeOfCareDetail,media,patient',
      ...(this.search ? { 'filter[search]': this.search } : {}),
      ...{
        'filter[status]': ['Awaiting Review', 'Reviewing'],
        'filter[owner]': this.organisationId
      },
      cancelToken: this.request.token
    };

    try {
      const response = await this.ophthalmologyConsultService.fetchAll(
        this.organisationId,
        {
          extraParams: requestConfig,
          paginationOptions: {
            page: this.page,
            sort: this.sort,
            perPage: 10
          },
          cancelToken: { ...this.request.token }
        }
      );
      this.request = null;

      // FIXME: fix rows type
      // @ts-ignore
      this.rows = response.data.map(
        (consult: OphthalmologyConsultViewModel) => ({
          id: consult.episodeOfCare.id,
          status: consult.status,
          created_at: consult.createdDate,
          ...(consult.hasConsultDate ? { consult_at: consult.consultDate } : {}),
          patient: {
            ...consult.patient,
            mrn: consult.patientMrn,
            nhs: getNhsNumberFromPatient(consult.patient)
          },
          clinic: consult.clinic,
          assigned_reviewer_user_name: consult.hasReviewer
            ? consult.reviewer.name
            : '',
          ...(consult.ambulatoryEncounter?.id ? { consultationId: consult.ambulatoryEncounter.id } : {}),
          review_overdue: consult.reviewIsOverdue,
          review: {
            ...consult.ophthalmologyDetails,
            id: consult.virtualEncounter?.id,
            locked: consult.isLocked
          },
          ...(consult.hasFileAttachmentId ? { fileAttachmentId: consult.fileAttachmentId } : {})
        })
      );

      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.filtered = this.search.length > 0;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
      if (!axios.isAxiosError(err)) {
        throw err;
      }
    } finally {
      this.loading = false;
    }
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  formatDob(isoDate: string) {
    return isoDate ? this.$d(getDobFromISOString(isoDate), 'shortMonth') : '';
  }

  getSexOrGender(patient: Patient): string | TranslateResult | undefined {
    if (this.sessionStore.currentOrganisation?.uses_gender && patient.gender) {
      const gender = this.genderOptions.find(
        (option) => option.value === patient.gender
      );
      return gender ? gender.label : patient.gender;
    }
    const sex = this.sexOptions.find((option) => option.value === patient.sex);
    return sex ? sex.label : patient.sex;
  }

  clickUnlockConsult(
    patientId: string,
    reviewId: string,
    lockedByUserFullName: string
  ) {
    this.modalTargetPatientId = patientId;
    this.modalTargetReviewId = reviewId;
    this.modalTargetLockedByUserFullName = lockedByUserFullName;
    this.isModalOpen = true;
  }

  async unlockConsult(reviewId: string) {
    await this.encounterService.update(reviewId, { locked: false });
    this.isModalOpen = false;
    return this.fetchConsults();
  }

  closeModal() {
    this.isModalOpen = false;
    this.modalTargetPatientId = '';
    this.modalTargetReviewId = '';
    this.modalTargetLockedByUserFullName = '';
  }

  viewSupportingDocument(id: number) {
    this.selectedDocumentId = id;
  }

  closeSupportingDocumentModal() {
    this.selectedDocumentId = null;
  }

  buildFullName(user: User) {
    return `${user.family_name} ${user.given_name}`;
  }

  async startReview(consult: Consult) {
    if (consult.review?.id) {
      await this.resumeReview(consult.review?.id);
    } else {
      await this.createReview(consult);
    }

    return await this.$router.push({
      name: 'patient-image-review',
      params: {
        organisationId: this.organisationId,
        patientId: consult.patient.id,
        episodeOfCareId: consult.id
      }
    });
  }

  async resumeReview(encounterId: string) {
    const participantTypes =
      await this.ophthalmologyConsultService.fetchCodeableConceptsByCategory(
        codeableConceptCategories.participantType
      );

    const createdStatus = findCodeableConceptByCode(
      'in-progress',
      this.statuses
    );

    const reviewParticipantType = findCodeableConceptByCode(
      'REV',
      participantTypes
    );

    const data = {
      status_id: createdStatus?.id,
      locked: true,
      participants: [
        {
          user_id: this.currentUserId,
          participant_type_id: reviewParticipantType?.id,
          participant_type_code: reviewParticipantType?.code
        }
      ]
    };

    try {
      return await this.encounterService.update(encounterId, data);
    } catch (error) {
      if (error.response?.status === 422) {
        this.errors = error.response.data?.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error'),
          label: error.response?.data?.message || ''
        });
      }
    }
    return;
  }

  async createReview(consult: Consult) {
    const participantTypes =
      await this.ophthalmologyConsultService.fetchCodeableConceptsByCategory(
        codeableConceptCategories.participantType
      );

    const createdStatus = findCodeableConceptByCode(
      'in-progress',
      this.statuses
    );
    const encounterClass = findCodeableConceptByCode(
      'VR',
      this.encounterClasses
    );
    const reviewParticipantType = findCodeableConceptByCode(
      'REV',
      participantTypes
    );

    const data = {
      patient_id: consult.patient?.id,
      encounter_class_id: encounterClass?.id,
      episode_of_care_id: consult.id,
      clinic_id: consult.clinic?.id,
      organisational_unit_id: this.organisationId,
      status_id: createdStatus?.id,
      locked: true,
      part_of: consult.consultationId,
      participants: [
        {
          user_id: this.currentUserId,
          participant_type_id: reviewParticipantType?.id,
          participant_type_code: reviewParticipantType?.code
        }
      ]
    };

    try {
      const virtualEncounter = await this.encounterService.create(data);
      return virtualEncounter.data;
    } catch (error) {
      if (error.response?.status === 422) {
        this.errors = error.response.data?.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.create-error'),
          label: error.response?.data?.message || ''
        });
      }
    }
    return;
  }
}
